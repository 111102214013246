.chartWrapper {
    background-color: rgba(255,255,255,0.65);
    transition: background-color .5s, box-shadow .5s;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    position: relative;
}
.chartWrapper:hover {
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}

.chartWrapperTitleContainer {
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 0 20px;
}

.chartWrapperTitleContainer>p {
    font-size: 18px;
    font-weight: 700;
}

.chartWrapperTitleContainer>p>span {
    font-size: 16px;
    font-weight: normal;
    margin: 5px 0 0 20px;
}

.chartWrapperContent {
    height: 100%;
}

.chartWrapperItems {
    display: flex;
    flex-wrap: wrap;
    margin: 0 16px;
}

.noDataMessage {
    text-align: center;
    position: relative;
    top: 50%;
}
.chartWrapper .downloadIcon svg {
    height: 20px;
    width: 20px;
    margin-right: 4px;
    margin-bottom: 4px;
}

.chartWrapper-totalButton {
    margin-bottom: 1rem;
    width: auto;
    margin-left: 16px;
    margin-right: auto;
    font-size: 14px;
    padding: 4px;
    background-color: #4292c6;
}