.sendButton {
    margin-bottom: 40px;
}

.legendMobile {
    font-size: 16px;
}

.thankMessage {
    font-size: 18px;
    font-weight: bold;
}